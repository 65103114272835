// Handle sidebar navigation
document.addEventListener('DOMContentLoaded', () => {
    const mobileMenuButton = document.getElementById('mobile-menu-button');

    if (mobileMenuButton) {
        const sidebar = document.getElementById('sidebar');
        const backdrop = document.getElementById('mobile-backdrop');
        let touchStartX = 0;
        let touchEndX = 0;

        // Toggle mobile menu
        function toggleMobileMenu(show) {
            if (show) {
                sidebar.classList.remove('-translate-x-full');
                backdrop.style.opacity = '1';
                backdrop.style.pointerEvents = 'auto';
                document.body.style.overflow = 'hidden';
            } else {
                sidebar.classList.add('-translate-x-full');
                backdrop.style.opacity = '0';
                backdrop.style.pointerEvents = 'none';
                document.body.style.overflow = '';
            }
        }

        // Handle mobile menu button click
        mobileMenuButton.addEventListener('click', () => {
            const isHidden = sidebar.classList.contains('-translate-x-full');
            toggleMobileMenu(isHidden);
        });

        // Handle backdrop click
        backdrop?.addEventListener('click', () => {
            toggleMobileMenu(false);
        });

        // Handle touch events for swipe to close
        sidebar?.addEventListener('touchstart', (e) => {
            touchStartX = e.changedTouches[0].screenX;
        });

        sidebar?.addEventListener('touchend', (e) => {
            touchEndX = e.changedTouches[0].screenX;
            if (touchStartX - touchEndX > 50) { // Swipe left
                toggleMobileMenu(false);
            }
        });

        // Handle dropdown toggles
        const dropdownToggles = document.querySelectorAll('[data-dropdown-toggle]');

        dropdownToggles.forEach(toggle => {
            toggle.addEventListener('click', (e) => {
                e.preventDefault();
                const dropdownId = toggle.getAttribute('data-dropdown-toggle');
                const dropdown = document.getElementById(dropdownId);
                const arrow = toggle.querySelector('.transform');

                // Close other dropdowns
                dropdownToggles.forEach(otherToggle => {
                    if (otherToggle !== toggle) {
                        const otherId = otherToggle.getAttribute('data-dropdown-toggle');
                        const otherDropdown = document.getElementById(otherId);
                        const otherArrow = otherToggle.querySelector('.transform');

                        if (otherDropdown && !otherDropdown.classList.contains('hidden')) {
                            otherDropdown.classList.add('hidden');
                            otherArrow?.classList.remove('rotate-180');
                        }
                    }
                });

                if (dropdown) {
                    dropdown.classList.toggle('hidden');
                    arrow?.classList.toggle('rotate-180');
                }
            });
        });

        // Close mobile menu on window resize if screen becomes large
        window.addEventListener('resize', () => {
            if (window.innerWidth >= 1024) { // lg breakpoint
                toggleMobileMenu(false);
            }
        });

        // Handle escape key
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                toggleMobileMenu(false);
            }
        });
    }
});

// Handle info tooltips
const tooltips = document.querySelectorAll('[data-tooltip]');

tooltips.forEach(tooltip => {
    const content = tooltip.getAttribute('data-tooltip');

    if (content) {
        tippy(tooltip, {
            content,
            placement: 'top',
            theme: 'dark',
            animation: 'scale',
        });
    }
});
